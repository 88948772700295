import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import usePageBody from '../../../hooks/usePageBody';

import Layout from '../../../components/Layout/main';
import GeneralHero from '../../../components/Hero/General';

const DynamicPageTemplate = ( { data } ) => {
	const { renderPageBody } = usePageBody();
	const {
		markdownRemark: {
			frontmatter: {
				title,
				generalBanner: { heading, text, background, buttons },
				pageBody
			}
		}
	} = data;

	return (
		<Layout title={title}>
			<Helmet>
				<link rel="stylesheet" href="https://kinetic-styles.netlify.app/Styles.css" />
			</Helmet>
			<header className="dynamicPageLayout">
				<GeneralHero
					heading={ heading }
					text={ text }
					background={ background }
					buttons={ buttons }
				/>
			</header>
			<main className="dynamicPageLayout">
				{ pageBody?.length && pageBody.map( ( component, i ) => renderPageBody( { ...component, i } ) ) }
			</main>
		</Layout>
	);
};

DynamicPageTemplate.propTypes = { data: PropTypes.object };

export default DynamicPageTemplate;

export const query = graphql`
query PageById($id: String!) {
	markdownRemark(id:{eq: $id}){
		frontmatter {
      title
      generalBanner {
        background
        heading
        text
        buttons {
          button {
          type
          link
          buttonText
          variant
          }
        }
      }
      pageBody {
        type
        htmlInput
        features {
          moduleId
          heading
          variant
          img {
            childImageSharp {
              fluid(maxWidth: 1400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
          croppedImg
          imgBorder
          backgroundColor
          backgroundImg
          textColor
          headerColor
          border
          copy {
            type
            text
            dropDownItem {
              title
              heading
              copy
            }
            button {
              type
              link
              buttonText
              variant
            }
          }
        }
        bulletLists {
          moduleId
          backgroundColor
          textColor
          header {
            title
            titleColor
            copy {
              text
              width
            }
          }
          bulletPoints {
            bullet {
              heading
              copy
            }
          }
        }
        cardGroup {
          heading
          headerColor
          cardsLayout
          card {
            type
            moduleId
            nav {
              dynamicLink
              staticLink
            }
            heading
            subHeading
            icon {
              childImageSharp {
                fluid(maxWidth: 1400, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
            img {
              childImageSharp {
                fluid(maxWidth: 1400, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
            navCopy
            featureCopy {
              type
              text
              buttons {
                type
                link
                buttonText
                variant
              }
            }
          }
        }
        featureCarousels {
          moduleId
          heading
          variant
          img {
            childImageSharp {
              fluid(maxWidth: 1400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          croppedImg
          imgBorder
          backgroundColor
          backgroundImg
          textColor
          headerColor
          border
          copy {
            type
            text
            dropDownItem {
              title
              heading
              copy
            }
            button {
              type
              link
              buttonText
              variant
            }
          }
        }
        banner {
          moduleId
          heading
          copy
          button {
            type
            link
            buttonText
            variant
          }
          backgroundColor
          backgroundImg {
            childImageSharp {
              fluid(maxWidth: 1400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
          headerColor
          textColor
          border
        }
      }
    }
  }
}
`;
